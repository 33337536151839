export default {
  marginBottom: ['2rem', '', '2.1875rem'],
  content: {
    alignItems: 'center',
    justifyContent: ['', '', 'space-between'],
  },
  nav: {
    WebkitOverflowScrolling: 'touch',
    overflowX: 'auto',
    justifyContent: ['', '', 'flex-end'],
    maxWidth: ['100%', '', '107.5rem'],
  },
  labels: {
    color: 'grayChateau',
    display: ['none', '', 'flex'],
    span: {
      marginLeft: '0.25rem',
    },
  },
  link: {
    display: 'block',
    marginLeft: '0.25rem',
    variant: 'links.common',
  },
};
