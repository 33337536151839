export default {
  loaderTextRow: {
    borderRadius: '0.25em',
    marginTop: ['0 !important', '', '0.5rem !important'],
    marginBottom: ['', '', '0.75rem'],
  },
  loaderTextBlock: {
    '.text-row': {
      borderRadius: '0.25em',
      height: ['2.25rem !important', '', '1.5rem !important'],
      marginTop: ['0.5rem !important ', '', '1.25rem !important'],
      '&:last-child': {
        height: ['', '', '1.3125rem !important'],
      },
    },
  },
};
