export default {
  alignItems: 'center',
  text: {
    fontWeight: 'bold',
    letterSpacing: '.05rem',
    marginRight: ['0.75rem', '1rem'],
    display: 'block',
    flexShrink: 0,
  },
};
