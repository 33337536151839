const commonStyle = {
  display: 'flex',
  color: 'rgba(255, 255, 255, 0.3)',
  svg: {
    fontSize: '1.125rem',
  },
  '&:hover': {
    color: 'alto',
  },
};

export default {
  position: 'relative',
  overflow: 'hidden',
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      display: ['none', '', 'block'],
      width: '100%',
      height: '14.5rem',
      backgroundImage:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);',
    },
  },
  content: {
    position: 'relative',
    height: ['6.5625rem', '', '18rem'],
    paddingY: ['0.5rem', '', '1rem'],
    zIndex: 1,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: '-0.25rem',
    color: 'white',
    textShadow: '0 3px 6px rgba(0, 0, 0, 0.28)',
  },
  heading: {
    alignItems: 'flex-end',
  },
  tools: {
    display: ['none', '', 'flex'],
    marginLeft: '0.75rem',
    marginBottom: '0.5rem',
    alignItems: 'center',
    a: {
      ...commonStyle,
    },
    buttonLink: {
      ...commonStyle,
      p: 0,
      flexShrink: 0,
      ml: '0.5rem',
      variant: 'transitions.default',
      backgroundColor: 'transparent',
    },
  },
  link: {
    color: 'white',
    display: ['none', '', 'block'],
    marginLeft: '1.25rem',
    variant: 'links.common',
    textShadow: '0 2px 6px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: 'alto',
    },
  },
};
