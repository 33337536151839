export default {
  gridTemplateColumns: [
    '1fr',
    'repeat(2, 1fr)',
    'repeat(3, 1fr)',
    'repeat(5, 1fr)',
  ],
  gridGap: ['1.25rem', '1.5rem', '1rem'],
  'li:first-of-type': {
    'button[class^="remove-button"], button[class*="remove-button"], div[class^="remove-button"], div[class*="remove-button"]': {
      display: 'none',
    },
  },
  brief: {
    buttons: {
      flexShrink: 0,
      alignItems: 'center',
      marginLeft: '0.625rem',
      '> *:not(:last-child)': {
        marginRight: '0.625rem',
      },
      svg: {
        fontSize: '1rem',
        variant: 'transitions.default',
        '&:hover': {
          opacity: ['', '', '0.6'],
        },
      },
    },
    select: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      marginBottom: ['0.125rem', '0.25rem'],
    },
    selectItem: {
      display: 'flex',
      svg: {
        fontSize: '1rem',
        variant: 'transitions.default',
        '&:hover': {
          opacity: ['', '', '0.6'],
        },
      },
      '&:not(:last-child)': {
        marginRight: '0.625rem',
      },
      '&.remove-button-desktop, &.remove-button-mobile': {
        color: ['black', '', 'black'],
        position: ['', '', 'absolute'],
        top: ['', '', '0.5625rem'],
        left: ['', '', '-1.75rem', '-1.875rem'],
        display: 'flex',
        flexShrink: 0,
      },
    },
  },
  parameters: {
    wrapper: {
      position: 'relative',
    },
    button: {
      position: ['', '', 'absolute'],
      top: ['', '', '0.75rem'],
      left: ['', '', '-1.75rem', '-1.875rem'],
      display: 'flex',
      alignSelf: ['flex-end', '', 'flex-start'],
      flexShrink: 0,
      marginBottom: ['0.5625rem', '', 0],
      svg: {
        marginLeft: ['0.625rem', '', 0],
        fontSize: '1rem',
        variant: 'transitions.default',
        '&:hover': {
          opacity: ['', '', 0.6],
        },
      },
    },
    select: {
      maxWidth: '15rem',
      marginBottom: ['', '', '1.125rem'],
    },
    others: {
      marginTop: '1.125rem',
    },
    mobileToolsWrapper: {
      display: ['block', '', 'none'],
    },
    mobileTools: {
      alignItems: 'center',
    },
    desktopToolsWrapper: {
      display: ['none', '', 'block'],
    },
  },
  box: {
    maxHeight: ['', '', '17.188rem'],
    minHeight: ['', '', '17.188rem'],
    overflow: ['', '', 'hidden'],
    paddingLeft: ['', '1.75rem', '1.875rem'],
    marginLeft: ['', '-1.75rem', '-1.875rem'],
  },
};
