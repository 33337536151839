export default {
  control: {
    height: '2.25rem',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '1.75rem',
    borderRadius: 0,
    border: 'none',
    padding: 0,
    minHeight: 'auto',
    boxShadow: 'none',
    cursor: 'pointer',
    transition: '250ms ease-in-out',
    svg: {
      fontSize: '1.125rem',
    },
    '&:hover': {
      color: ['', '', 'rgb(0 0 0 / 0.7)'],
    },
  },
  valueContainer: {
    padding: '0 1.5rem 0 0',
    height: 'inherit',
  },
  singleValue: {
    margin: 0,
    transform: 'none',
    top: 0,
    color: 'currentColor',
    maxWidth: '100%',
  },
  placeholder: {
    margin: 0,
  },
  indicators: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    svg: {
      color: '#000',
    },
  },
  menu: {
    margin: 0,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#FFF',
    borderRadius: 0,
    boxShadow: '0 0 1.25rem rgba(0, 0, 0, 0.16)',
    overflow: 'hidden',
    top: 'calc(100% - 2.25rem)',
    sizes: {
      default: {
        width: [
          'calc(100vw - 7rem)',
          'calc(50vw - 3rem)',
          'calc(30vw - 3rem)',
          'calc(20vw - 3rem)',
          '15rem',
        ],
      },
      medium: {
        width: ['100%', '100%', '100%', '100%', '100%'],
      },
      small: {
        width: [
          'calc(100vw - 7rem)',
          'calc(50vw - 3rem)',
          'calc(30vw - 3rem)',
          'calc(20vw - 3rem)',
          '15rem',
        ],
      },
    },
  },
  option: {
    fontWeight: 700,
    fontFamily: 'museo-sans, sans-serif',
    letterSpacing: '.05rem',
    padding: '0.375rem 0.625rem',
    cursor: 'pointer',
  },
};
