export default {
  width: '100%',
  alignItems: 'flex-start',
  flexDirection: ['column', '', 'row'],
  justifyContent: 'space-between',
  marginTop: ['2.25rem', '', '5.375rem'],
  content: {
    flexShrink: 0,
    flexDirection: ['column', '', 'row'],
  },
  label: {
    marginTop: ['1.25rem', '', 0],
    marginLeft: ['', '', '1.25rem'],
    alignSelf: ['', '', 'flex-end'],
  },
  mobileLabel: {
    display: ['', '', 'none'],
  },
  desktopLabel: {
    display: ['none', '', 'inline-block'],
  },
  banner: {
    width: '100%',
    maxWidth: ['100%', '', '38.125rem'],
    marginTop: ['2rem', '', 0],
    marginLeft: ['', '', '3rem'],
  },
};
