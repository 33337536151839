import 'assets/fonts/fonts.css';

import buttons from 'components/atoms/Buttons/theme';
import banner from 'components/atoms/Banner/theme';
import forms from 'components/atoms/Fields/theme';
import image from 'components/atoms/Image/theme';
import heading from 'components/atoms/Heading/theme';
import parametersList from 'components/atoms/ParametersList/theme';
import nav from 'components/molecules/Nav/theme';
import action from 'components/molecules/Action/theme';
import addCategory from 'components/molecules/AddCategory/theme';
import preset from 'components/molecules/Preset/theme';
import header from 'components/organisms/Header/theme';
import hero from 'components/organisms/Hero/theme';
import tools from 'components/sections/Tools/theme';
import placeholderSkeleton from 'components/organisms/PlaceholderSkeleton/theme';
import briefList from 'components/organisms/BriefList/theme';
import section from 'components/sections/Section/theme';
import cms from 'components/sections/CMS/theme';
import inner from 'components/styles/Inner/theme';
import links from './links';
import text from './text';

const theme = {
  colors: {
    background: '#FFF',
    white: '#FFF',
    text: '#000',
    grayChateau: '#9FA8AD',
    alto: '#DEDEDE',
    doveGray: '#707070',
    cerulean: '#03A9F4',
    dodgerBlue: '#14C5FF',
  },
  fonts: {
    museo: 'museo-sans, sans-serif',
    spartan: `'Spartan', sans-serif`,
  },
  breakpoints: ['768px', '1024px', '1440px', '1920px'],
  sizes: {
    smallPhone: '23.375rem',
  },
  fontWeights: {
    light: 300,
    medium: 500,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  transitions: {
    default: {
      transition: '250ms ease-in-out',
    },
  },
  layout: {
    wrapper: {
      paddingY: ['2rem', '', '2.1875rem'],
    },
    container: {
      maxWidth: '100rem',
    },
  },
  buttons,
  banner,
  forms,
  image,
  heading,
  parametersList,
  nav,
  action,
  addCategory,
  preset,
  header,
  hero,
  tools,
  section,
  cms,
  placeholderSkeleton,
  briefList,
  inner,
  links,
  text,
};

export default theme;

export type Theme = typeof theme;

export type WithTheme<T = unknown> = { theme: Theme } & T;
