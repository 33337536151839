export default {
  base: {
    variant: 'transitions.default',

    '&[disabled]': {
      opacity: 0.6,
      pointerEvents: 'none',
    },
  },
  primary: {
    variant: 'buttons.base',
    borderRadius: '0.25rem',
    padding: ['1.25rem 1rem', '', '1.25rem'],
    lineHeight: 1,
    fontSize: '1rem',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '.05rem',
    backgroundColor: 'cerulean',
    width: ['100%', '', 'auto'],
    '&:hover, &:focus': {
      backgroundColor: ['', '', 'dodgerBlue'],
    },
  },
  icon: {
    variant: 'buttons.base',
    padding: 0,
    borderRadius: 0,
    width: 'auto',
    height: 'auto',
  },
};
