export default {
  color: 'grayChateau',
  item: {
    '&:not(:last-child)': {
      marginBottom: '1rem',
    },
    '&:nth-last-of-type(2)': {
      display: 'none',
    },
  },
  wrapper: {
    display: ['none', '', 'block'],
  },
  heading: {
    display: 'block',
    marginTop: [0, '', '0.375rem'],
    marginBottom: [0, '', '0.675rem'],
    overflowWrap: 'break-word',
    variant: 'transitions.default',
  },
  button: {
    p: 0,
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    span: {
      variant: 'transitions.default',
    },
    '&:hover span': {
      textShadow: '0 0 .65px #000, 0 0 .65px #000',
    },
  },
};
