export default {
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: 'doveGray',
  color: 'currentColor',
  width: '100%',
  height: '5.625rem',
  textAlign: 'center',
  padding: ['1rem', '', '2rem'],
};
