export default {
  wrapper: {
    paddingTop: ['2rem', '', '4.5rem'],
  },
  content: {
    display: ['flex', '', 'block'],
    flexDirection: 'column',
  },
  heading: {
    marginBottom: ['', '', '1.875rem'],
  },
};
