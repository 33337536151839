export default {
  borderBottom: '1px solid',
  borderColor: 'grayChateau',
  paddingTop: ['1.375rem', '', '2rem'],
  paddingBottom: ['1.25rem', '', '1.75rem'],
  alignItems: 'center',
  justifyContent: 'space-between',
  link: {
    display: ['none', 'block'],
    marginLeft: '1rem',
    color: 'cerulean',
    variant: 'links.common',
    '&:hover': {
      color: 'dodgerBlue',
    },
  },
};
