export default {
  wrapper: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    '.gatsby-image-wrapper': {
      width: '100%',
      height: '100%',
    },
  },
  media: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
};
