export default {
  heading1: {
    fontSize: ['1.875rem', '', '4.125rem'],
    fontWeight: 'black',
    lineHeight: [1.125, '', 1],
    letterSpacing: '.05rem',
  },
  heading2: {
    fontFamily: 'spartan',
    fontSize: ['2rem', '', '2.5rem'],
    fontWeight: 'extraBold',
    letterSpacing: '.05rem',
  },
  heading3: {
    fontFamily: 'spartan',
    fontSize: '1.75rem',
    fontWeight: 'extraBold',
    letterSpacing: '.05rem',
  },
  body: {
    fontSize: '1rem',
    fontWeight: 'light',
  },
  typo1: {
    fontSize: ['1rem', '', '1.1875rem'],
    fontWeight: 'bold',
    letterSpacing: '.05rem',
  },
  typo2: {
    fontSize: '0.875rem',
    fontWeight: 'medium',
  },
  typo3: {
    fontSize: '0.875rem',
    fontWeight: 'light',
    letterSpacing: '.05rem',
  },
  typo4: {
    fontSize: '1rem',
    letterSpacing: '.05rem',
  },
  typo5: {
    fontSize: '1.375rem',
    fontWeight: 'bold',
    fontFamily: 'spartan',
    lineHeight: 1.2,
  },
};
