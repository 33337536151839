export default {
  link: {
    a: {
      '&:first-of-type': {
        display: ['', '', 'none'],
      },
      '&:not(:last-child)': {
        marginRight: '1.75rem',
      },
      '&.active': {
        color: 'text',
      },
      variant: 'links.nav',
    },
  },
};
