export default {
  nav: {
    color: 'grayChateau',
    variant: 'text.typo3',
    '&:hover': {
      color: 'dodgerBlue',
    },
  },
  request: {
    color: 'cerulean',
    textTransform: 'uppercase',
    letterSpacing: '.05rem',
    fontWeight: 'bold',
    '&:hover': {
      color: 'dodgerBlue',
    },
  },
  common: {
    variant: 'text.typo3',
    '&:hover': {
      color: 'dodgerBlue',
    },
  },
};
