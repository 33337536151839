export default {
  wrapper: {
    maxWidth: ['', '', '15.3125rem'],
    height: ['', '', '100%'],
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    border: '1px solid',
    borderColor: 'rgba(112, 112, 112, 0.03)',
    borderRadius: '0.3125rem',
    padding: ['1rem 0.625rem', '', '3rem 1.5rem 1.25rem 1.5rem'],
    textAlign: 'center',
    variant: 'transitions.default',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    cursor: 'pointer',
  },
  button: {
    display: 'block',
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    padding: 0,
    background: 'transparent',
  },
  content: {
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  title: {
    flexGrow: 1,
    textAlign: ['left', '', 'center'],
    order: [2, '', 1],
    opacity: 0.35,
    marginTop: ['0.25rem', '', 0],
    marginBottom: ['', '', '0.625rem'],
    br: {
      display: ['none', '', 'block'],
    },
  },
  icon: {
    display: 'flex',
    flexShrink: 0,
    order: [1, '', 2],
    marginTop: ['', '', '0.625rem'],
    marginRight: ['0.625rem', '', 0],
    marginBottom: ['', '', '2rem'],
    opacity: 0.35,
    svg: {
      fontSize: ['2rem', '', '3rem'],
    },
  },
  info: {
    variant: 'text.typo4',
    opacity: 0.35,
    display: ['none', '', 'block'],
  },
  box: {
    alignItems: 'center',
    flexDirection: ['row', '', 'column'],
    flexGrow: 1,
  },
};
