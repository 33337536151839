export default {
  wrapper: {
    padding: ['', '', '5rem 0'],
  },
  title: {
    width: ['100%', 'auto'],
    marginRight: '1rem',
    marginBottom: ['', '1rem'],
  },
  heading: {
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  action: {
    marginBottom: ['1.3125rem', '', '1.5625rem'],
  },
  wysiwyg: {
    color: 'doveGray',
    fontSize: ['1rem', '', '1.25rem'],
    lineHeight: '1.35',
    'p + p, p + h2, p + h3, p + h4': {
      marginTop: '1.125rem',
    },
    'p + ul, p + ol': {
      marginTop: '0.75rem',
    },
    h2: {
      fontSize: ['1.375rem', '', '1.75rem'],
      fontWeight: 'bold',
      marginBottom: '0.75rem',
    },
    h3: {
      fontSize: ['1.25rem', '', '1.5rem'],
      fontWeight: 'bold',
      marginBottom: '0.75rem',
    },
    h4: {
      fontSize: ['1.125rem', '', '1.25rem'],
      fontWeight: 'bold',
      marginBottom: '0.75rem',
    },
    'strong, bold': {
      fontWeight: 'bold',
    },
    a: {
      color: 'cerulean',
      '&:hover': {
        color: 'dodgerBlue',
      },
    },
    ul: {
      listStyleType: 'disc',
    },
    ol: {
      listStyleType: 'decimal',
    },
    'ul, ol': {
      paddingLeft: '2.5rem',
      marginBottom: '1.125rem',
      a: {
        color: 'currentColor',
      },
    },
  },
};
