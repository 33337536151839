import select from './Select/theme';

export default {
  select,
  labelWrapper: {
    display: 'block',
    width: ['auto', '', '100%'],
  },
  labelCustom: {
    marginBottom: '0.5rem',
    display: 'block',
  },
  sizes: {
    default: {
      fontSize: '1.75rem',
      fontWeight: 700,
      fontFamily: `'Spartan', sans-serif`,
      color: '#000',
    },
    medium: {
      fontSize: 'inherit',
      fontWeight: 300,
    },
    small: {
      fontSize: '0.875rem',
      fontWeight: 300,
      color: '#9FA8AD',
    },
  },
};
